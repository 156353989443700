import React , {useEffect} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom';
import Header from '../components/Header/Main'
import Footer from '../components/Footer/Main'
// import Home from '../components/Home/Main'
import About from '../components/About-Us/Main'
import Contact from '../components/Contact-Us/Main'
import Blog from '../components/Blog/Main';
import BlogDdeatils from '../components/Blogdetails/Main'
import BlogDdeatils2 from '../components/Blogdetails/Main2'
import BlogDdeatils3 from '../components/Blogdetails/Main3'
import BlogDdeatils4 from '../components/Blogdetails/Main4'
import BlogDdeatils5 from '../components/Blogdetails/Main5'
import BlogDdeatils6 from '../components/Blogdetails/Main6'
// import Service from '../components/Service/Main'
import Medicaloncology from '../components/Servicesdeatils/Medicaloncology'
import Radiationoncology from '../components/Servicesdeatils/Radiationoncology'
import Oncosurgery from '../components/Servicesdeatils/Oncosurgery'
import Palliativecare from '../components/Servicesdeatils/Palliativecare'
import Genetictesting from '../components/Servicesdeatils/Genetictesting'
import Othertreatments from '../components/Servicesdeatils/Othertreatments'
import Gallery from '../components/Gallery/Main'
import Pricing from '../components/PagesMen/Pricing/Main';
import Faq from '../components/PagesMen/Faq/Main'
import Team from '../components/PagesMen/Team/Main'
import TeamDetails from '../components/PagesMen/TeamDetails/Main'
import Shop from '../components/PagesMen/Shop/Main'
import ShopDetails from '../components/PagesMen/ShopDetails/Main'
import Hometwo from '../components/HomeTwo/Main'
import HomeThree from '../components/HomeThree/Main'

function Index() {

  const location = useLocation();
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
}, [path]);

  return (
    <>
      <Header/>
      <Routes>
        <Route path="/" element={<Hometwo />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={ <Contact/>} />
        <Route path="/blog" element={ <Blog/>} />
        <Route path="/blogdetails" element={ <BlogDdeatils/>} />
        <Route path="/blogdetails2" element={ <BlogDdeatils2/>} />
        <Route path="/blogdetails3" element={ <BlogDdeatils3/>} />
        <Route path="/blogdetails4" element={ <BlogDdeatils4/>} />
        <Route path="/blogdetails5" element={ <BlogDdeatils5/>} />
        <Route path="/blogdetails6" element={ <BlogDdeatils6/>} />
        <Route path="/service" element={ <Medicaloncology/>} />
        <Route path="/medicaloncology" element={ <Medicaloncology/>} />
        <Route path="/radiationoncology" element={ <Radiationoncology/>} />
        <Route path="/oncosurgery" element={ <Oncosurgery/>} />
        <Route path="/palliativecare" element={ <Palliativecare/>} />
        <Route path="/genetictesting" element={ <Genetictesting/>} />
        <Route path="/othertreatments" element={ <Othertreatments/>} />
        <Route path="/gallery" element={ <Gallery/>} />
        <Route path="/pricing" element={ <Pricing/>} />
        <Route path="/faq" element={ <Faq/>} />
        <Route path="/team" element={ <Team/>} />
        <Route path="/teamdetails" element={ <TeamDetails/>} />
        <Route path="/shop" element={ <Shop/>} />
        <Route path="/Shopdetails" element={ <ShopDetails/>} />
        <Route path="/hometwo" element={ <Hometwo/>} />
        <Route path="/homethree" element={ <HomeThree/>} />
      </Routes>
      <Footer/>
    </>
  )
}

export default Index